@import 'shared/stylesheets/skeletonFramework';

.NowPlaying {
    display: flex;
    // justify-content: flex-start;
    padding: 2.5rem 2.5rem 1.125rem 2.5rem;

    /* Smaller than phablet (also point before grid becomes active) */
    @media (max-width: 550px) {
        flex-direction: column;
    }
}

.NowPlayingAlbumArtWrapper {
    flex: 1;
}
.NowPlayingAlbumArt {
    // display: flex;
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0px 3px 14px 3px rgba(255,255,255,.25);
    // flex-grow: 0.5;
    // flex-shrink: 0.5;
}
.NowPlayingTrackDetails {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 2.5rem;

    > * {
        margin-bottom: 0;
    }

    :nth-last-child(-n+2) {
        // font-weight: 400;
        color: #aaa;
    }

    /* Larger than phablet (also point when grid becomes active) */
    @media (min-width: 550px) {
        margin-top: 0;
        margin-left: 2.5rem;
    }
}

.NowPlayingProgress {
    height: 0.125rem;
    width: 100%;
    margin: 2.5rem 0;
    background-color: white;
    border-radius: 1rem;
}
.NowPlayingControls {
    // display: flex;
    // flex-direction: column;
    // align-self: flex-end;
    // align-items: flex-start;
    display: flex;
    justify-content: space-between;
}

.MusicPlayerControl {
    margin: 1rem 0;
    max-height: 30px;
}

input[type=range] {
    height: 5px;
	-webkit-appearance: none;
	width: 100%;
	margin-bottom: 10px;
	border-radius: 8px;
	background: #3b7677;
	transition: background 0.2s ease;
	cursor: pointer;
}
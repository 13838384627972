@import 'shared/stylesheets/skeletonFramework';

.List {
    display: block;
    flex-direction: column;
    flex-grow: 1;
}

.RawList {
    list-style: none;
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
    flex-direction: column;
}

.ListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    // padding-left: 2.5rem;
    // border-radius: 0.5rem;
}
// .ListItem:hover {
//     background-color: rgba(255,255,255,.25);
// }
.ListItemLabel {
    display: inline;
    margin-left: 2rem;
    vertical-align: middle;
}
.ListItemHighlighted {
    width: 1rem;
    height: 2.5rem;
    background: linear-gradient(60deg, #5b99e5 , #590688, #c0117b, #fd5754, #f3863c);
    border-radius: 1rem;
}
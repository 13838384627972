@import 'shared/stylesheets/skeletonFramework';

.IconSVG {
    cursor: pointer;
}

.Disabled {
    filter: invert(50%) sepia(0%) saturate(91%) hue-rotate(223deg) brightness(101%) contrast(88%);
    cursor: auto;
}

.Small {
    width: 2rem;
    height: auto;
}

.Medium {
    width: 3.4rem;
    height: auto;
}

.Large {
    width: 5rem;
    height: auto;
}
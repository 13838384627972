@import 'shared/stylesheets/skeletonFramework';

.Small {
    width: 1.5rem;
    height: auto;
}

.Wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}

.ButtonText {
    font-weight: 600;
    background: -webkit-linear-gradient(0deg, #5b99e5 0%, #590688 30%, #c0117b 45%, #fd5754 70%, #f3863c 85%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
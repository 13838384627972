.Pill {
    background-color: rgb(50, 50, 50);
    padding: 0.75rem 1.75rem;
    margin: 0 0.5rem;
    border-radius: 2rem;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
}

.Pill:first-child {
    margin-left: 2.5rem;
}
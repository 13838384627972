@import 'shared/stylesheets/skeletonFramework';

.SideBar { 
    height: 100vh;
    background-color: rgba(22,22,23,0.8);
    // TODO revise this, dont think it works how we think
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    flex-direction: 'column';

    /* Smaller than phablet (also point before grid becomes active) */
    @media (max-width: 550px) {
        position: fixed;
        transition: transform .6s cubic-bezier(0, .52, 0, 1);
        max-width: 90vw;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        z-index: 10;
    }

}
.SideBarClosed {
    /* Smaller than phablet (also point before grid becomes active) */
    @media (max-width: 550px) {
        transform: translate3d(-100vw, 0, 0);
    }
}

.Spacer {
    margin-right: 2.5rem;
}
.SideBar > * {
    margin-left: 2.5rem;
}
.SideBarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5rem;
    margin-right: 2.5rem;
}
.CompanyLabel {
     text-align: left;
     margin-bottom: 0;
}

.Spacer {
    height: 5rem;
}

.CustomItem {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
}
.CustomItem:hover {
    background-color: rgba(255,255,255,.25);
}
.SidebarListItemIcon, 
.SidebarListItemLabel {
    vertical-align: middle;
    margin-bottom: 0;
}
.SidebarListItemLabel {
    display: inline;
    margin-left: 2rem;
}
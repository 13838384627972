@import 'shared/stylesheets/skeletonFramework';

.Modal {
    position: absolute;
    z-index: 500;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    margin: 1rem 2rem;
    padding: 1rem 2.5rem;
    border-radius: 1rem;
    background-color: rgba(22,22,23,1);
    box-shadow: 1px 1px 1px black;
    transition: all 0.3s ease-out;
    flex-direction: column;

    // display: flex;
    // flex-direction: column;
    // top: 50%;
    // left: 50%;
    // background-color: rgba(22,22,23,0.97);
    // padding: 0 2rem 2.5rem;
    // border-radius: 0.5rem;
    // box-shadow: 0px -1px 20px 0px rgba(22,22,23,0.97);

    // position: fixed;
    // width: 70%;
    // border: 1px solid #ccc;
    // padding: 16px;
    // left: 15%;
    // top: 30%;
    // box-sizing: border-box;
}

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(22,22,23,0.8);
}
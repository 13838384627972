.Gutter {
    overflow-x: clip;
    -ms-overflow-style: none;  // Hides scrollbar for IE and Edge
    scrollbar-width: none; // Hides scrollbar for Firefox
}
.Gutter::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari and Opera
}

.GutterList {
    display: flex;
    // margin-left: 2.5rem;
}
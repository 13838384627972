@import 'shared/stylesheets/skeletonFramework';

// overrides
:root {
    --PhoneInputCountrySelectArrow-color: black;
}
.PhoneStyles {
    background-color: white;
    border-radius: 4px;
    border: 1px solid gray;
}
.PhoneStyles > div {
    padding: 0 1rem;
}
.PhoneStyles > input {
    border: none !important; // why do we need this flag..
}

// custom styles
.Content {
    padding: 9rem 0 0 0;

    /* Larger than mobile */
    @media (min-width: 400px) {
        padding-top: 18rem;
    }
}

.RegistrationInput {
    width: 100%;
    margin-bottom: 3rem;
    /* Larger than mobile */
    @media (min-width: 400px) {
        width: 25%;
    }
}

.Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
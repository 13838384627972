.ControlsContainer {
    --highlight-width: auto;
    --highlight-x-pos: 0;
  
    // display: flex;
    // flex-direction: row;
    margin-top: 2.5rem;
    margin-bottom: 3rem;
}

.Controls {
    display: inline-flex;
    justify-content: space-between;
    background: rgb(50, 50, 50);
    // box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(50, 50, 50);
    border-radius: 0.5rem;
    max-width: 750px;
    padding: 0px 2px;
    margin: auto;
    overflow: hidden;
    position: relative;
    // ONLY USED TO SUPPORT FULL WIDTH CONTROLS
    box-sizing: border-box;
    width: 100%;
}

.Controls input {
    opacity: 0;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.Controls::before {
    content: "";
    background: rgb(100, 100, 100);
    // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #5b99e5 , #590688, #c0117b, #fd5754, #f3863c);
    border-radius: 4px;
    width: var(--highlight-width);
    transform: translateX(var(--highlight-x-pos));
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 0;
    z-index: 0;
}

/* Only allow transitions once component is ready */
.Controls.Ready::before {
    transition: transform 0.3s ease, width 0.3s ease;
}

.Segment {
    /* width: 100%; uncomment for each segment to have matching width */
    width: 100%;
    min-width: 50px;
    position: relative;
    text-align: center;
    z-index: 1;
}

.Segment label {
    cursor: pointer;
    display: block;
    font-weight: 700;
    // LR VALUE ONLY USED TO SUPPORT FULL WIDTH CONTROLS
    padding: 0.5rem;
    transition: color 0.5s ease;
    margin: 0;
}

.Segment.Active label {
    color: #fff;
}
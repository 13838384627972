@import 'shared/stylesheets/skeletonFramework';

.Alert {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // width: 100%;
    margin: 1rem 1rem;
    padding: 1rem 2.5rem;
    border-radius: 1rem;
    background-color: rgba(22,22,23,0.8);
}
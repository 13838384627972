@import 'shared/stylesheets/skeletonFramework';

.LibraryPage {
    // height: 100vh;
    // overflow: auto;
}

.LibraryPage > * {
    margin: 2.5rem;
}

.MyPartiesSection {
    background-color: rgba(22,22,23,0.8);
    border-radius: 1rem;
    box-shadow: 0px 13px 14px 4px rgba(0,0,0,0.45);
    padding: 1rem 2.5rem;
}

.StartPartyButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
}
.StartPartyButtonIcon {
    margin: 0 1rem;
    margin-left: -1rem;
    margin-right: 2rem;
}
.StartPartyButtonText {
  background: -webkit-linear-gradient(0deg, #5b99e5 0%, #590688 30%, #c0117b 45%, #fd5754 70%, #f3863c 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.CustomHeader {
    display: flex;
}
.CustomHeader > span {
    padding: 1rem 2.5rem;
}
.CustomHeader > span:not(:last-child) {
    margin-right: 2.5rem;
}

.HeaderItemActive {
    background-color: rgba(22,22,23,0.8);
    border-radius: 1rem;
    box-shadow: 0px 13px 14px 4px rgba(0,0,0,0.45);
}
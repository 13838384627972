@import 'shared/stylesheets/skeletonFramework';

.Toggle {
    margin-left: 1rem;
    position: relative;
    appearance: none; outline: none;
    width: 5rem;
    height: 2.25rem;
    background-color: #ffffff;
    border: 1px solid #D9DADC;
    border-radius: 50px; box-shadow: inset -20px 0 0 0 #ffffff;
    transition-duration: 200ms;
}

.Toggle:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0,0,0,0.2);
}

.Toggle:checked {
    border-color: #4ED164;
    box-shadow: inset 2.75rem 0 0 0 #4ED164;
}

.Toggle:checked:after {
    left: 2.75rem;
    box-shadow: -2px 4px 3px rgba(0,0,0,0.05);
    }
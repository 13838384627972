@import 'shared/stylesheets/skeletonFramework';

.Small {
    width: 2rem;
    height: 2rem;
}

.Medium {
    width: 3.4rem;
    height: 3.4rem;
}

.Large {
    width: 5rem;
    height: 5rem;
}
@import 'shared/stylesheets/skeletonFramework';

.SettingsPage > * {
    margin: 2.5rem
}

.Settings {
    display: flex;
}

.Header {
    display: flex;
    :nth-child(2) {
        margin-left: 2rem;
    }
}

.SettingsList, .SettingDetails {
    border-radius: 1rem;
    background-color: rgba(22,22,23,0.8);
    padding: 1rem 2.5rem;
}
.SettingsList {
    width: 100%;

    /* Larger than phablet (also point when grid becomes active) */
    @media (min-width: 550px) {
        width: 25%;
    }
}
.SettingDetails {
    flex: 1;
    display: flex;
    flex-direction: column;
    
    /* Larger than phablet (also point when grid becomes active) */
    @media (min-width: 550px) {
        margin-left: 1rem;
    }
}
.SettingItemToggle {
    display: flex;
    padding-bottom: 1rem;
}

.SettingsDetailItem {
    justify-content: space-between;
    // align-items: flex-start;
    // flex-basis: 0;
    // margin-right: 0;
}

.SettingsListItem, .SettingsDetailItem, .SettingHeader, .Container {
    display: flex;
    padding: 0.5rem 0;
    
    span {
        padding-left: 2rem;
    }
}

// TODO extarct this separator
.Rounded {
    border-top: 4px solid #bbb;
    border-radius: 5px;
}

.Auth {
    color: #1ed760
}
@import 'shared/stylesheets/skeletonFramework';

.Label {
    font-weight: bold;
    letter-spacing: 0;
    margin-bottom: 1.75rem;
}

.Padding {
    padding: 0 2.5rem;
}

.Label.XSmall {
    font-size: 1.4rem;
    line-height: 0.9;
}
.Label.Small {
    font-size: 2rem;
    line-height: 1.6;
}
.Label.Medium {
    font-size: 3rem;
    line-height: 1.9;
}
.Label.Large {
    font-size: 4.4rem;
}

/* Larger than mobile */
@media (min-width: 400px) {
    // .Label.XSmall {
    //     font-size: 1.4rem;
    //     line-height: 0.9;
    // }
    // .Label.Small {
    //     font-size: 2rem;
    //     line-height: 1.6;
    // }
    // .Label.Medium {
    //     font-size: 3rem;
    //     line-height: 1.9;
    // }
    .Label.Large {
        line-height: 2.2;
    }
}
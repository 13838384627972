.Albums {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.Albums > * {
    flex: 1;
    width: 40%;
}

.ArtistImageWrapper {
    flex: 0.4;
    margin-right: 2.5rem;
}
.ArtistImage {
    width: 100%;
    border-radius: 2rem;
    box-shadow: 0px 3px 14px 3px rgba(255,255,255,.25);
}
.ArtistDetails {
    flex: 4
}
@import 'shared/stylesheets/skeletonFramework';

:root {
    // --rsbs-backdrop-bg: rgba(0, 0, 0, 0.6);
    --rsbs-bg: rgba(22,22,23,0.97);
    --rsbs-handle-bg: #fff;
    // --rsbs-max-w: auto;
    // --rsbs-ml: env(safe-area-inset-left);
    // --rsbs-mr: env(safe-area-inset-right);
    // --rsbs-overlay-rounded: 16px;
  }

.Player { 
    flex-direction: column;
    overflow: hidden;

    /* Larger than phablet (also point when grid becomes active) */
    @media (min-width: 550px) {
        background-color: rgba(22,22,23,0.8);
        height: 100vh;
    }
}

.ModeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2.5rem;
}
.ModeViewPartyMembersButton {
    margin-right: 1rem;
}
.ModeLabel {
    margin: 0;
}
.ModeLabelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Settings {
    margin-left: 2.5rem;
    margin-right: 2rem;
}

.PartyMemberListWrapper {
    overflow-x: scroll;
    -ms-overflow-style: none;  // Hides scrollbar for IE and Edge
    scrollbar-width: none; // Hides scrollbar for Firefox
}
.PartyMemberListWrapper::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari and Opera
}
.PartyMemberList {
    display: flex;
    // margin-left: 2.5rem;
}
.UserIcon {
    border: 2.5px solid gray;
    border-radius: 2rem;
    height: 50%;
}
.UserIcon {
    margin-right: 1rem;
}

.W > div > div {
    display: flex;
    flex-direction: row;
}

.InactivePlayer {
    margin: 0 2.5rem;
}
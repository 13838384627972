.Track {
    display: flex;
    flex-direction: row;
    padding: 1rem 0 0;
    width: 100%;
    box-sizing: border-box;
}

.TrackAlbumArtWrapper {
    flex: 0.75;

    /* Larger than phablet (also point when grid becomes active) */
    @media (min-width: 550px) {
        flex: 0.45;
    }
}

.TrackHover {
    padding: 1rem 2.5rem 0rem;
}
.TrackHover:hover {
    background-color: rgba(255,255,255,.25);
}
.TrackAlbumArt {
    width: 100%;
    border-radius: 0.5rem;
    // box-shadow: 0px 3px 14px 1px rgba(255,255,255,.25);
}

.TrackDetails {
    flex: 4;
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;

    > * {
        margin-bottom: 0;
    }
    :last-child {
        color: #aaa;
    }
}
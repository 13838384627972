.Card {
    background-color: rgba(22,22,23,0.8);
    border-radius: 1rem;
    box-shadow: 0px 13px 14px 4px rgba(0,0,0,0.45);
    padding: 2rem 2.5rem;
    margin-bottom: 2.5rem;
    display: flex;
}
.LayoutVertical {
    flex-direction: column;
}
.LayoutHorizontal {
    flex-direction: row;
}

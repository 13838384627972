@import 'shared/stylesheets/skeletonFramework';

.Toast {
    width: 100%;
    margin-right: 16px;
    margin-top: 16px;
    padding: 16px;
    position: relative;
    border: 1px solid #d7d7d7;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: black;
}
@import 'shared/stylesheets/skeletonFramework';

.ModeSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 2.5rem;
}
.ModeViewPartyMembersButton {
    margin-right: 1rem;
}
.ModeLabel {
    margin: 0;
    // line-height: 1.8;
}
.ModeLabelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.Settings {
    margin-left: 2.5rem;
    margin-right: 2rem;
}

.PartyMemberListWrapper {
    overflow-x: scroll;
    -ms-overflow-style: none;  // Hides scrollbar for IE and Edge
    scrollbar-width: none; // Hides scrollbar for Firefox
}
.PartyMemberListWrapper::-webkit-scrollbar {
    display: none; // Hide scrollbar for Chrome, Safari and Opera
}
.PartyMemberList {
    display: flex;
    // margin-left: 2.5rem;
}
// .UserIcon {
//     border: 2.5px solid gray;
//     border-radius: 2rem;
//     height: 50%;
// }
.UserIcon {
    width: 48px;
    height: 48px;
    cursor: pointer;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #fff;
    display: inline-block;
    box-sizing: border-box;
  
    div {
      color: #000;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: inherit;
      font-weight: inherit;
    }
  }
.UserIcon {
    margin-right: 1rem;
}

.Gutter > div > div {
    display: flex;
    flex-direction: row;
}

.Alert {
    position: absolute;
    display: flex;
    flex-direction: column;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    background-color: rgba(22,22,23,0.97);
    padding: 0 2rem 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 0px 3px 14px 3px rgba(22,22,23,0.97);
    box-shadow: 0px -1px 20px 0px rgba(22,22,23,0.97);
}

.Icon {
    margin-left: 2rem;
}
@import 'shared/stylesheets/skeletonFramework';


.PlayingNext {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Header {
    display: flex;
    justify-content: space-between;
    margin: 1.125rem 2.5rem;

    .Labels {
        flex-direction: column;
        // align-items: flex-start;

        // > * {
        //     flex-grow: 2;
        // }

        > :nth-child(2) {
            margin-top: 0.5rem;
            color: #aaa;
        }
    }
    
    .Controls {
        > *:not(:last-child) {
            margin-right: 2.5rem;
        }

        // .ControlSelected {
        // }
    }
}

.UpcomingTrackDetails {
    flex: 4;
    display: flex;
    flex-direction: column;
    margin-left: 2.5rem;

    > * {
        margin-bottom: 0;
    }
    :last-child {
        color: #aaa;
    }
}
.Label {
    // border-top: 0.5px solid rgba(255,255,255,0.45);
    margin-bottom: 0;
    vertical-align: middle;
    display: inline;
}
.HeaderIconSVG {
    align-items: center;
    margin: auto;
    background-color: aqua;
    // vertical-align: middle;
}
.UpcomingTrackList {
    margin-bottom: 0;
    margin-left: 2.5rem;
}

.UpcomingTrack {
    display: flex;
    flex-direction: row;
}
.UpcomingTrackAlbumArtWrapper {
    flex: 0.75;

    /* Larger than phablet (also point when grid becomes active) */
    @media (min-width: 550px) {
        flex: 0.45;
    }
}
.UpcomingTrackAlbumArt {
    width: 100%;
    border-radius: 0.5rem;
    // box-shadow: 0px 3px 14px 1px rgba(255,255,255,.25);
}

.ShowHistoryButton {
    vertical-align: middle;
    margin-left: 1rem;
}
@import 'shared/stylesheets/skeletonFramework';

.Content {
    padding: 9rem 0 0 0;

    /* Larger than mobile */
    @media (min-width: 400px) {
        padding-top: 18rem;
    }
}

.VerificationCodeInput {
    width: 100%;
    margin-bottom: 4rem;

    /* Larger than mobile */
    @media (min-width: 400px) {
        width: 25%;
    }
}

.RequestNewCode {
    color: #1eaedb
}

.Footer {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.PlaylistAlbumArtWrapper {
    flex: 0.75;
    margin-right: 2.5rem;
}
.PlaylistAlbumArt {
    width: 100%;
    border-radius: 1rem;
    box-shadow: 0px 3px 14px 3px rgba(255,255,255,.25);
}
.PlaylistDetails {
    flex: 4
}
.ArtistImageWrapper {
    flex: 0.4;
    margin-right: 2.5rem;
}
.ArtistImage {
    width: 100%;
    border-radius: 50%;
    box-shadow: 0px 3px 14px 3px rgba(255,255,255,.25);
}
.ArtistDetails {
    flex: 4
}
@import 'shared/stylesheets/skeletonFramework';

.SearchPage {
    // margin: 0 0 2.5rem;
    display: flex;
    flex-direction: column;
    // height: 100vh;
    // overflow: auto;
}

.Header {
    margin: 0 2.5rem;
}

.SearchBar {
    display: flex;
}

.SearchInput {
    height: 25px !important;
    width: 100%;
    -webkit-appearance: none !important;
    background-color: rgb(50, 50, 50) !important;
    color: #fff !important;
    // input[type=search] {
    // }
}

.SegmentedControl { 
    margin: 0 2.5rem;
}

.ResponsiveList {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.Results {
    margin-top: 2rem;
}
